<template>
    <header id="header" class="sticky top-0 z-50 bg-white">

        <Search v-if="isSearchComponentLoaded" v-model="searchOpen"></Search>

        <nav aria-label="Navigation" class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8">
            <Link id="focusLogo" class="-m-1.5 p-1.5" href="/">
                <span class="sr-only">SAMOS e-commerce LTD</span>
                <svg id="header-logo" class="h-8 w-auto" viewBox="0 0 185 40">
                    <g fill="#000">
                        <path
                            d="m16.8 9.6c-1-2.8-2.9-3.4-4.8-3.4-2.8 0-4.5 1.7-4.5 4 0 2.1 1.8 3.1 3.7 3.7l4.4 1.5c5.8 1.8 7.4 5.5 7.4 8.8 0 5.9-5.1 9.4-11.1 9.4-5.4 0-10.5-2.8-11.9-8.2l5.3-1.3c.7 2.5 3.5 4.3 6.6 4.3s5.3-1.8 5.3-4.2c0-2.1-1.7-3.2-3.9-3.9l-4.1-1.4c-4.9-1.5-7.6-4.2-7.6-8.5.1-5.4 4.1-9.4 10.2-9.4 4.3 0 8.5 1.8 10.3 7.4z"/>
                        <path
                            d="m43.1 1c4.1 0 8.2 1.4 11.1 5.7v-5h6v31.3h-6v-5c-2.9 4.2-7 5.7-11.1 5.7-9.2 0-15.2-7.4-15.2-16.3s6-16.4 15.2-16.4zm.8 5.3c-6.4 0-10 5-10 10.9s3.6 10.9 10 10.9c6.2 0 10.4-4.6 10.5-10.9-.1-6.2-4.3-10.9-10.5-10.9z"/>
                        <path
                            d="m89.9 15.3c0-6.6-3-8.9-6.3-8.9s-8.7 2.2-8.7 12.1v14.5h-6v-31.3h6v6.3c1.3-4.6 5.4-7 9.9-7 2.9 0 7.4 1 9.8 6.6 2.5-5.5 6.7-6.6 10.4-6.6 7.2 0 11.9 4.2 11.9 14.3v17.6h-6v-17.6c0-6.6-3-8.9-6.3-8.9-2.9 0-8.4 1.8-8.7 11.3v15.2h-6z"/>
                        <path
                            d="m178.8 9.6c-1-2.8-2.9-3.4-4.8-3.4-2.8 0-4.5 1.7-4.5 4 0 2.1 1.8 3.1 3.7 3.7l4.4 1.5c5.8 1.8 7.4 5.5 7.4 8.8 0 5.9-5.1 9.4-11.1 9.4-5.4 0-10.4-2.8-11.9-8.2l5.3-1.3c.7 2.5 3.5 4.3 6.6 4.3s5.3-1.8 5.3-4.2c0-2.1-1.7-3.2-3.9-3.9l-4.1-1.4c-4.9-1.5-7.5-4.2-7.5-8.5 0-5.3 4.1-9.3 10.1-9.3 4.3 0 8.5 1.8 10.3 7.4z"/>
                        <path
                            d="m140.4 0c-9.3 0-16.9 7.7-16.9 17.1v1c0 .2 0 .5.1.7 1.2 13.2 16.8 21.1 16.8 21.1s15.8-8 16.9-21.1c0-.2 0-.5 0-.7 0-.3 0-.7 0-1 0-9.4-7.6-17.1-16.9-17.1zm10.7 17.2c0 6.3-4.8 11.4-10.7 11.4s-10.6-5.1-10.6-11.4c0-6.4 4.8-11.5 10.6-11.5 5.9-.1 10.7 5.1 10.7 11.5z"/>
                    </g>
                </svg>
            </Link>
            <div class="flex md:hidden">
                <button class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700" type="button"
                        @click="isMobileMenuComponentLoaded = true; mobileMenuOpen = true">
                    <span class="sr-only">Open main menu</span>
                    <Bars3Icon aria-hidden="true" class="h-6 w-6"/>
                </button>
            </div>
            <PopoverGroup class="hidden md:flex md:gap-x-6 lg:gap-x-12">

                <button class="-my-2.5 rounded-md text-electric-violet-600" type="button" @click="isSearchComponentLoaded=true; searchOpen = true;">
                    <span class="sr-only">Search</span>
                    <MagnifyingGlassIcon aria-hidden="true" class="h-6 w-6"/>
                </button>

                <Popover v-slot="{ open }" class="relative">
                    <PopoverButton
                        :class="{ 'text-electric-violet-600' : !open, 'text-white bg-cape-palliser-500': open, 'bg-cape-palliser-500 text-white': $page.url.startsWith('/services') }"
                        class="flex items-center gap-x-1 text-sm font-semibold leading-6 outline-electric-violet-500 rounded-3xl rounded-br-none px-3 py-2 focus:outline-electric-violet-500">
                        Services
                        <ChevronDownIcon
                            :class="{ 'text-electric-violet-600': !open, 'rotate-180 transform text-white': open, 'text-white': $page.url.startsWith('/services') }"
                            aria-hidden="true"
                            class="h-5 w-5 flex-none"/>
                    </PopoverButton>

                    <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0"
                                enter-to-class="opacity-100" leave-active-class="transition ease-in duration-150"
                                leave-from-class="opacity-100" leave-to-class="opacity-0">
                        <PopoverPanel
                            class="absolute right-0 top-full z-10 w-96 rounded-3xl rounded-tr-none shadow-lg ring-1 ring-gray-900/5 bg-cape-palliser-500 overflow-hidden">
                            <div class="p-4">
                                <div v-for="item in services" :key="item.name"
                                     class="relative rounded-lg p-4 hover:bg-cape-palliser-600">
                                    <Link :href="item.href" class="block text-sm font-semibold leading-6 text-white">
                                        {{ item.name }}
                                        <span class="absolute inset-0"/>
                                    </Link>
                                    <p class="mt-1 text-sm leading-6 text-white font-light">{{ item.description }}</p>
                                </div>
                            </div>
                        </PopoverPanel>


                    </transition>
                </Popover>

                <Popover v-slot="{ open }" class="relative">
                    <PopoverButton
                        :class="{ 'text-electric-violet-600' : !open, 'text-electric-violet-600 bg-pot-pourri-200': $page.url.startsWith('/pricing') | open }"
                        class="flex items-center gap-x-1 text-sm font-semibold leading-6 outline-electric-violet-500 rounded-3xl rounded-br-none px-3 py-2">
                        Pricing
                        <ChevronDownIcon
                            :class="{ 'rotate-180 transform': open, 'text-electric-violet-600': $page.url.startsWith('/pricing') | !open }"
                            aria-hidden="true"
                            class="h-5 w-5 flex-none text-electric-violet-600"/>
                    </PopoverButton>

                    <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0"
                                enter-to-class="opacity-100" leave-active-class="transition ease-in duration-150"
                                leave-from-class="opacity-100" leave-to-class="opacity-0">
                        <PopoverPanel
                            class="absolute right-0 top-full z-10 w-96 rounded-3xl rounded-tr-none shadow-lg ring-1 ring-gray-900/5 bg-pot-pourri-200 overflow-hidden">
                            <div class="p-4">
                                <div v-for="item in pricing" :key="item.name" class="relative rounded-lg p-4 hover:bg-pot-pourri-300">
                                    <Link :href="item.href" class="block text-sm font-semibold leading-6 text-electric-violet-600">
                                        {{ item.name }}
                                        <span class="absolute inset-0"/>
                                    </Link>
                                    <p class="mt-1 text-sm leading-6 text-electric-violet-500 font-light">{{ item.description }}</p>
                                </div>
                            </div>
                        </PopoverPanel>
                    </transition>
                </Popover>

                <Popover v-slot="{ open }" class="relative">
                    <PopoverButton
                        :class="{ 'text-electric-violet-600' : !open, 'text-electric-violet-600 bg-cape-palliser-200': $page.url.startsWith('/resources') || open }"
                        class="flex items-center gap-x-1 text-sm font-semibold leading-6 outline-electric-violet-500 rounded-3xl rounded-br-none px-3 py-2">
                        Resources
                        <ChevronDownIcon
                            :class="{ 'text-electric-violet-600': !open, 'rotate-180 transform text-electric-violet-600': open }"
                            aria-hidden="true"
                            class="h-5 w-5 flex-none"/>
                    </PopoverButton>

                    <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0"
                                enter-to-class="opacity-100" leave-active-class="transition ease-in duration-150"
                                leave-from-class="opacity-100" leave-to-class="opacity-0">
                        <PopoverPanel
                            class="absolute right-0 top-full z-10 w-96 rounded-3xl rounded-tr-none shadow-lg ring-1 ring-gray-900/5 bg-cape-palliser-200 overflow-hidden">
                            <div class="p-4">
                                <div v-for="item in resources" :key="item.name"
                                     class="relative rounded-lg p-4 hover:bg-cape-palliser-300">
                                    <Link :href="item.href" class="block text-sm font-semibold leading-6 text-electric-violet-600">
                                        {{ item.name }}
                                        <span class="absolute inset-0"/>
                                    </Link>
                                    <p class="mt-1 text-sm leading-6 text-electric-violet-500 font-light">{{ item.description }}</p>
                                </div>
                            </div>
                        </PopoverPanel>
                    </transition>
                </Popover>


                <Popover v-slot="{ open }" class="relative">
                    <PopoverButton
                        :class="{ 'text-electric-violet-600' : !open, 'text-white bg-electric-violet-700': $page.url.startsWith('/blog') || open }"
                        class="flex items-center gap-x-1 text-sm font-semibold leading-6 outline-electric-violet-500 rounded-3xl rounded-br-none px-3 py-2">
                        Blog
                        <ChevronDownIcon
                            :class="{ 'text-electric-violet-600': !open, 'rotate-180 transform text-white': open, 'text-white' : $page.url.startsWith('/blog') }"
                            aria-hidden="true"
                            class="h-5 w-5 flex-none"/>
                    </PopoverButton>

                    <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0"
                                enter-to-class="opacity-100" leave-active-class="transition ease-in duration-150"
                                leave-from-class="opacity-100" leave-to-class="opacity-0">
                        <PopoverPanel
                            class="absolute right-0 top-full z-10 w-96 rounded-3xl rounded-tr-none shadow-lg ring-1 ring-gray-900/5 bg-electric-violet-500 overflow-hidden">
                            <div class="bg-electric-violet-600 p-4 rounded-3xl rounded-tr-none shadow-sm">
                                <div class="relative rounded-lg p-4 hover:bg-electric-violet-700">
                                    <Link class="block text-sm font-semibold leading-6 text-white capitalize" href="/blog">
                                        Blog
                                        <span class="absolute inset-0"/>
                                    </Link>
                                </div>
                                <div v-for="item in blog_menu.categories" :key="item.id"
                                     class="relative rounded-lg p-4 hover:bg-electric-violet-700">
                                    <Link :href="'/blog/' + item.slug"
                                          class="block text-sm font-semibold leading-6 text-white capitalize">
                                        {{ item.title }}
                                        <span class="absolute inset-0"/>
                                    </Link>
                                </div>
                            </div>
                            <div class="p-4">
                                <div v-if="blog_menu.last_blog_post" class="relative rounded-lg p-4 hover:bg-electric-violet-600">
                                    <Link :href="'/blog/' + blog_menu.last_blog_post.category.slug + '/' + blog_menu.last_blog_post.slug"
                                          class="block text-sm font-semibold leading-6 text-white">
                                        Recent Post
                                        <span class="absolute inset-0"/>
                                    </Link>
                                    <p class="mt-1 text-xs leading-6 text-white font-medium">{{ blog_menu.last_blog_post.human_readable_published_at }}</p>
                                    <p class="text-sm leading-6 text-white font-light">{{ blog_menu.last_blog_post.title }}</p>
                                </div>
                                <div v-if="blog_menu.most_read_post" class="relative rounded-lg p-4 hover:bg-electric-violet-600">
                                    <Link :href="'/blog/' + blog_menu.most_read_post.category.slug + '/' + blog_menu.most_read_post.slug"
                                          class="block text-sm font-semibold leading-6 text-white">
                                        Most Read
                                        <span class="absolute inset-0"/>
                                    </Link>
                                    <p class="mt-1 text-xs leading-6 text-white font-medium">{{ blog_menu.most_read_post.human_readable_published_at }}</p>
                                    <p class="text-sm leading-6 text-white font-light">{{ blog_menu.most_read_post.title }}</p>
                                </div>
                            </div>
                        </PopoverPanel>
                    </transition>
                </Popover>

                <div
                    class="text-electric-violet-600 flex items-center gap-x-1 text-sm font-semibold leading-6 outline-electric-violet-500 rounded-3xl rounded-br-none px-3 py-2">
                    <a href="https://connect.samos-e.com/login" target="_blank">
                        Login
                    </a>
                </div>
            </PopoverGroup>


        </nav>
        <MobileMenu
            v-if="isMobileMenuComponentLoaded"
            v-model:isSearchComponentLoaded="isSearchComponentLoaded"
            v-model:mobileMenuOpen="mobileMenuOpen"
            v-model:searchOpen="searchOpen"
            :blog_menu="blog_menu"
            :pricing="pricing"
            :resources="resources"
            :services="services"
        ></MobileMenu>
    </header>
</template>

<script setup>
import {computed, defineAsyncComponent, ref} from 'vue';
import {Popover, PopoverButton, PopoverGroup, PopoverPanel} from '@headlessui/vue'
import {Bars3Icon} from '@heroicons/vue/24/outline'
import {MagnifyingGlassIcon} from '@heroicons/vue/24/solid'
import {ChevronDownIcon} from '@heroicons/vue/20/solid'
import {Link, router, usePage} from '@inertiajs/vue3';

const Search = defineAsyncComponent(() =>
    import('@/Components/Search.vue')
);
const MobileMenu = defineAsyncComponent(() =>
    import('@/Components/MobileMenu.vue')
);

const page = usePage();
const blog_menu = computed(() => page.props.blog_menu);

const services = [
    {
        name: 'Parcel Delivery',
        href: '/services/parcel-delivery',
        description: 'Take your products to new markets with SAMOS!'
    },
    {
        name: 'Parcel Returns',
        href: '/services/parcel-returns',
        description: 'Make returns easy for you and your customers'
    },
    {name: 'Integrations', href: '/services/integrations', description: 'Channels & Marketplaces'},
    {name: 'Parcel Tracking', href: '/services/parcel-tracking', description: 'Track your SAMOS parcels'},
];

const pricing = [
    {
        name: 'Shipping Rates',
        href: '/pricing/samos-shipping-rates',
        description: 'Learn more about our simple pricing. No surcharges to you or your customer'
    },
];

const resources = [
    {name: 'FAQs', href: '/resources/frequently-asked-questions', description: 'Frequently asked questions'},
    {name: 'Sustainability', href: '/resources/sustainability', description: 'Helping to deliver a better future.'},
    {
        name: 'Duties & Taxes',
        href: '/resources/duties-and-taxes',
        description: 'Understand the costs of international shipping'
    },
    {
        name: 'Volumetric weight explained',
        href: '/resources/volumetric-weight-explained',
        description: 'What is volumetric weight and why do I need to know about it?'
    }, {
        name: 'How to create shipping labels',
        href: '/resources/how-to-create-shipping-labels',
        description: 'The Do\'s and Don\'ts of creating labels'
    }
];


const searchOpen = ref(false);
const isSearchComponentLoaded = ref(false);

const isServer = typeof document === 'undefined';

if (!isServer) {
    router.on('start', (event) => {
        document.getElementById('focusLogo').focus(); //used so that the popovers can close
        mobileMenuOpen.value = false;
    });
}

const mobileMenuOpen = ref(false);
const isMobileMenuComponentLoaded = ref(false);
</script>
